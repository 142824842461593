import React, { Component } from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";

class FactsRadioGroup extends Component {
    constructor(props) {
        super(props);
        let _listData = store.getState()[this.props.listdset];

        let valueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        this.state = {
            value: (valueFromState === null ? "" : valueFromState),
            listData: _listData
        }
    }

    componentDidMount() {
        this.mounted = true;

        this.unsubscribe = store.subscribe(() => {
            if (this.mounted) {
                let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.props.bind + "]");
                if (this.state.value !== newState) {
                    if (newState === null) newState = "";
                    this.setState({
                        textValue: newState
                    });

                }
                let newList = store.getState()[this.props.listdset];
                let thisListString = JSON.stringify(this.state.listData);
                let newListString = JSON.stringify(newList);
                if (thisListString !== newListString) {
                    this.setState({
                        listData: newList
                    });
                }
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();

    }

    async saveCurrentValueToDataset() {
        await ExecuteLayoutEventMethods([

            {
                exec: "setdatasetfield",
                args: {
                    dset: this.props.dset,
                    fieldname: this.props.bind,
                    data: this.state.value
                }
            }
        ]);
    }

    async runCustomOnChangeEvents() {

        await this.saveCurrentValueToDataset();
        await ExecuteLayoutEventMethods(this.props.whenchange, this.state);

    }
    async handleOnChange(e) {
        this.setState({
            value: e.target.value
        }, () => {
            this.runCustomOnChangeEvents();
        });

    }


    ripOffControlSpecificAttributes() {
        const excluded = ["resolveprops", "format", "refData", "rowindex"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));

    }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        return (
            <RadioGroup  {...newProps}
                value={this.state.value}
                onChange={(e) => this.handleOnChange(e)} >
                {this.state.listData?.map((t, index) => {
                    return <FormControlLabel {...this.props.inputProps} value={t[this.props.valuefield]} key={index} control={<Radio />} label={t[this.props.textfield]} />
                })}
            </RadioGroup>

        );
    }
}

export default FactsRadioGroup;

