import { differenceInMilliseconds, startOfToday, addMilliseconds } from "date-fns";
import { format } from "date-fns/esm";
import { getTZPrefix } from "./globals";

export const DATEDIFF = "(datediff)",  DATEDIFFNTZ = "(datediffntz)", IFNULL = "(ifnull)", FORMAT = "(dtformat)", JOINSTR = "(joinstr)",
    EQUALS = "(eq)", NOTEQUALS = "(neq)", IFTRUE = "(iftrue)", IFFALSE = "(iffalse)", BOOL_NOT = "(not)",
    TOSTRING = "(tostr)", STRAFTER = "(strafter)", STR_REPLACE = "(strreplace)", JSON_PARSE = "(jsonparse)", 
    MATH_ADD = "(+)", MATH_SUB = "(-)", MATH_MULTI = "(*)", MATH_DIV = "(/)", MATH_MOD = "(%)", MATH_POW = "(^)",
    LESS_THAN = "(<)", GREATER_THAN = "(>)", LESSTHAN_OR_EQUAL = "(<=)", GREATERTHAN_OR_EQUAL = "(>=)",
    IF_NAN = "(ifnan)", NUM_FORMAT = "(numformat)", LIST_REVERSE = "(listreverse)", LIST_SUM = "(listsum)", LIST_COUNT = "(listcount)",LIST_INDEX = "(listindex)",
    LIST_FILTER = "(listfilter)",
    OBJ_TYPE = "(type)", OBJ_GET = "(get)";
export const funcs = [DATEDIFF, DATEDIFFNTZ, IFNULL, FORMAT, JOINSTR, EQUALS, NOTEQUALS, IFTRUE, IFFALSE, BOOL_NOT, TOSTRING, STRAFTER,STR_REPLACE, JSON_PARSE, 
    LESS_THAN, GREATER_THAN, LESSTHAN_OR_EQUAL, GREATERTHAN_OR_EQUAL, 
    MATH_ADD, MATH_SUB, MATH_MULTI, MATH_DIV, MATH_MOD, MATH_POW, IF_NAN, NUM_FORMAT,LIST_REVERSE, LIST_COUNT, LIST_SUM, LIST_INDEX, LIST_FILTER, OBJ_TYPE, OBJ_GET];

export function dyadicFuncExecutor(funcName, firstValue, secondValue) {
    let firstValMath = 0;
    let secondValMath = 0;
    if ([MATH_ADD, MATH_SUB, MATH_MULTI, MATH_DIV, MATH_MOD, MATH_POW].includes(funcName)) {
        firstValMath = parseFloat(firstValue);
        secondValMath = parseFloat(secondValue);
        if (isNaN(firstValMath)) firstValMath = 0;
        if (isNaN(secondValMath)) secondValMath = 0;
    }
    switch (funcName) {

        case DATEDIFF:
            return _dateDiffExec(firstValue, secondValue);
        case DATEDIFFNTZ: 
            return _dateDiffExec(firstValue, secondValue, true);
        case IFNULL:
            return _ifNullExec(firstValue, secondValue);
        case FORMAT:
            return _formatDateTime(firstValue, secondValue);
        case JOINSTR:
            return (firstValue ?? "") + (secondValue ?? "");
        case STRAFTER:
            return firstValue.substr(firstValue.indexOf(secondValue) + 1);
        case STR_REPLACE:
            let replaceValues =  secondValue.split("=>"); 
            return firstValue.replace(replaceValues[0], replaceValues[1]);
        case EQUALS:
            if(secondValue == null) return secondValue == firstValue; 
            return secondValue.split("||").some(el => el == firstValue) ; //No type checking
        case NOTEQUALS:
            if(secondValue == null) return secondValue != firstValue; 
            return secondValue?.split("||").every(el => el != firstValue); //No type checking
        case LESS_THAN : 
            return firstValue < secondValue; 
        case LESSTHAN_OR_EQUAL:
            return firstValue <= secondValue; 
        case GREATER_THAN:
            return firstValue > secondValue; 
        case GREATERTHAN_OR_EQUAL:
            return firstValue >= secondValue
        case TOSTRING:
            return JSON.stringify(firstValue);
        case JSON_PARSE:
            return JSON.parse(firstValue);
        case MATH_ADD:
            return firstValMath + secondValMath;
        case MATH_SUB:
            return firstValMath - secondValMath;
        case MATH_MULTI:
            return firstValMath * secondValMath;
        case MATH_DIV:
            return firstValMath / secondValMath;
        case MATH_MOD:
            return firstValMath % secondValMath;
        case MATH_POW:
            return Math.pow(firstValMath, secondValMath);
        case IF_NAN:
            if (isNaN(firstValue)) return secondValue;
            else return firstValue;
        case NUM_FORMAT:
            return formatNumber(firstValue,secondValue ); 
           
        case LIST_SUM:
            return calculateListSummary(firstValue, secondValue);
        case LIST_REVERSE:
            if(Array.isArray(firstValue)){
                return firstValue?.slice()?.reverse(); 
            }
            return firstValue;
        case LIST_COUNT:
            return calculateListCount(firstValue);
        case LIST_INDEX:
            return getListIndexMatchingValue(firstValue, secondValue);
        case LIST_FILTER:
            return getFilteredList(firstValue, secondValue);
        case BOOL_NOT:
            return !firstValue;
        case OBJ_TYPE:
            if (firstValue === null || typeof firstValue === "undefined") return "null";
            let valType = typeof firstValue;
            if (valType === "object" && Array.isArray(firstValue)) valType = "array";
            return valType;
        case OBJ_GET:
            if (typeof firstValue === "object") {
                return firstValue[secondValue];
            }
            return firstValue;
        default:
            return null;
    }
}


function formatNumber(number, format){
    let numSplit = number?.toString().split("."); 
    if(format?.startsWith("N") && numSplit){
        let prec = parseInt(format.replace("N", "")); 
        // let reg = new RegExp("", "g"); 
        let whole =  numSplit[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ; 
        let decim = (numSplit.length > 1  && prec > 0 ? "0." + numSplit[1] : "0.0"); 
        decim = parseFloat(decim).toFixed(prec); 
        let returnVal = whole + (decim.length > 1 ?  "." +  decim.split(".")[1] : ""); 
 
        return  returnVal; 
    }
    else{
         let toFix = parseInt(format);
        let valNumber = parseFloat(number);
        if (isNaN(valNumber)) return number;
        if (isNaN(toFix)) toFix = 2;
        return valNumber.toFixed(toFix);
    }
   }

function calculateListSummary(dsetArray, fieldName) {
    let total = 0;
    if (Array.isArray(dsetArray)) {
        for (let di of dsetArray) {
            let currentItem = di[fieldName];
            if (typeof currentItem !== 'undefined') {
                let ciValue = parseFloat(currentItem);
                if (!isNaN(ciValue)) {
                    total += ciValue;
                }
            }
        }
    }
    return total;
}

function getListIndexMatchingValue(dsetArray, fieldNameAndValue) {
     if (Array.isArray(dsetArray)) {
        let fieldArray =  fieldNameAndValue.split("||"); 
        return dsetArray.findIndex(t=> {
            let assertedMatches = [];
            for(let fa of fieldArray){ 
                let keyfa = fa.split("=>"); 
                assertedMatches.push(t[keyfa[0]] == keyfa[1]); 
            }
            return assertedMatches.every(t=> t=== true); 
        }); 
       
    }
    return -1; 
 }

 
function getFilteredList(dsetArray, fieldNameAndValue) {
    if (Array.isArray(dsetArray)) {
       let fieldArray =  fieldNameAndValue.split("||"); 
       return dsetArray.filter(t=> {
           let assertedMatches = [];
           for(let fa of fieldArray){ 
               let keyfa = fa.split("=>"); 
               assertedMatches.push(t[keyfa[0]] == keyfa[1]); 
           }
           return assertedMatches.every(t=> t=== true); 
       }); 
      
   }
   return []; 
}
function calculateListCount(dsetArray) {
    let count = 0;
    if (Array.isArray(dsetArray)) {
        count = dsetArray.length;
    }
    return count;
}
function _dateDiffExec(firstValue, secondValue, noTimeZone = false) {
    if(!noTimeZone){ 
        if (typeof firstValue === "string") firstValue += getTZPrefix();
        if (typeof secondValue === "string") secondValue += getTZPrefix();
    }
    let firstDateValue = Date.parse(firstValue),
        secondDateValue = Date.parse(secondValue);
    if (!firstDateValue) firstDateValue = new Date();
    if (!secondDateValue) secondDateValue = new Date();
    let millis = differenceInMilliseconds(secondDateValue, firstDateValue);
    return addMilliseconds(startOfToday(), millis);
}

const _ifNullExec = (firstValue, secondValue) => firstValue ?? secondValue;  //Return second value if first value is null



function _formatDateTime(firstValue, formatString) {
    try {
        if (typeof firstValue === "string") firstValue += getTZPrefix();
        let dateValue = Date.parse(firstValue);
 
        if (!dateValue) return null; // dateValue = new Date();
        if (!formatString) formatString = "dd-MMM-yyyy";
        return format(dateValue, formatString);
    }
    catch {
        return firstValue;
    }
}